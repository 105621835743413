import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { authModalAtom } from 'src/components/AuthModal/atoms/authModalAtom';

import {
  isListingSaveSearchHiddenAtom,
  listingSaveSearchUserDataAtom,
} from 'src/libs/contact';

import type { PriceProposalDialogState } from 'src/views/RealEstate';

import type { ContactFormProps } from '..';
import { ContactForm } from '..';
import { ListingSaveSearch } from '../ListingSaveSearch';

import css from '../index.module.scss';

interface ContactFormInPageProps extends ContactFormProps {
  trackingKey?: string;
  isPriceProposalVisible?: boolean;
  openPriceProposalDialog?: (
    openedFrom: PriceProposalDialogState['openedFrom']
  ) => void;
}

export function ContactFormInPage({
  trackingKey,
  isPriceProposalVisible = false,
  openPriceProposalDialog,
  ...contact
}: ContactFormInPageProps) {
  const authModal = useAtomValue(authModalAtom);
  const [listingSaveSearchUserData, setListingSaveSearchUserData] = useAtom(
    listingSaveSearchUserDataAtom
  );

  const setIsListingSaveSearchHidden = useSetAtom(
    isListingSaveSearchHiddenAtom
  );

  const { data, trovakasa } = contact;

  return (
    <>
      {listingSaveSearchUserData &&
        trovakasa &&
        authModal.open === false &&
        data?.dataType === 'real-estate' && (
          <ListingSaveSearch
            onClose={() => {
              setListingSaveSearchUserData(undefined);
              setIsListingSaveSearchHidden(true);
            }}
            cityId={data.properties[0].location.cityId}
            macroZoneId={data.properties[0].location.macrozoneId}
            realEstateData={data}
            trovakasa={trovakasa}
            contactFormData={listingSaveSearchUserData}
          />
        )}

      <div className={css['in-lead--inPage']} data-tracking-key={trackingKey}>
        <ContactForm
          {...contact}
          isPriceProposalVisible={isPriceProposalVisible}
          openPriceProposalDialog={openPriceProposalDialog}
        />
      </div>
    </>
  );
}
