import { atom } from 'jotai';
import type { AgencyListQueryParams } from 'src/repository/agencies/types';

export const agencyListQueryParamsAtom = atom<AgencyListQueryParams>({});

const agencyListFormValueAtom = atom<AgencyListQueryParams>({});

// An atom that depends on another (read state of another atom), if it should not modify
// the reading atom(as in our case), must rely on another support atom, which will be
// overwritten by the "write" method.
// The atom that still needs to be read and written is the last one and not the support atom.
// rif. https://jotai.org/docs/guides/composing-atoms#overriding-default-atom-values and
// https://jotai.org/docs/core/atom#atom
export const agencyListFormAtom = atom(
  (get) => {
    const hasFormValues = Object.keys(get(agencyListFormValueAtom)).length > 0;

    return hasFormValues
      ? get(agencyListFormValueAtom)
      : get(agencyListQueryParamsAtom);
  },
  (_get, set, newValue: AgencyListQueryParams) => {
    set(agencyListFormValueAtom, newValue);
  }
);

export const agencyListServerSearchAtom = atom<boolean>(true);
