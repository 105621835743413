import { useTranslations } from '@pepita-react/i18n';

import type { RealEstate, TrovaKasa } from 'src/types/real-estate';
import type { Language } from 'src/types/translations';

import { nonFalsy } from 'src/utils/common';
import type { Trans } from 'src/utils/i18nFormatters';
import { currencyFormatter } from 'src/utils/i18nFormatters';
import { getRangeValues } from 'src/utils/searchBadges';

import {
  getAuctionBadge,
  getEnergyRangeBadge,
  getGeographyInfoBadge,
  getPropertyTypeBadge,
  getRoomsBadge,
  getTypologyBadge,
} from '../ListingSaveSearch/utils';

export const useListingSaveSearchBadges = (
  trovakasa: TrovaKasa,
  realEstateData: RealEstate
) => {
  const { trans, lang } = useTranslations() as {
    trans: Trans;
    lang: Language;
  };
  const {
    animals,
    balcony,
    elevator,
    inAuction,
    maxLocals,
    maxPrice,
    maxSurface,
    minLocals,
    minPrice,
    minSurface,
    noAuction,
    propertyTypeId,
    smoker,
    terrace,
  } = trovakasa;
  const property = realEstateData.properties[0];
  const { contract, contractValue } = realEstateData;
  const { location, typology, category, condition, bathrooms, energy } =
    property;
  const { city, macrozone } = location || {};
  const formattedMinPrice = minPrice
    ? currencyFormatter(trans, lang, minPrice, '€')
    : undefined;
  const formattedMaxPrice = maxPrice
    ? currencyFormatter(trans, lang, maxPrice, '€')
    : undefined;
  const formattedMinSurface = minSurface ? `${minSurface} m²` : undefined;
  const formattedMaxSurface = maxSurface ? `${maxSurface} m²` : undefined;

  return [
    // Category badge (Residential, commercial, etc.)
    category.name,

    // Geography info badge (City, zones)
    getGeographyInfoBadge(trans, city, macrozone),

    // Typology badge (Flat, penthouse, attic, etc.)
    getTypologyBadge(trans, typology, category.id, contract, contractValue),

    // Condition badge (excellent, refurbished, good, etc.)
    condition,

    // Price range badge (€ 220.000 - 280.000)
    getRangeValues(trans, formattedMinPrice, formattedMaxPrice),

    // Surface range badge (360 m² - 440 m²)
    getRangeValues(trans, formattedMinSurface, formattedMaxSurface),

    // Bathrooms badge (3 bathrooms)
    bathrooms &&
      bathrooms !== '0' &&
      trans('search_formatter_nr_bathroom', {
        count: parseInt(bathrooms),
        params: [bathrooms],
      }),

    // Property type badge (Entire property, bare property, etc.)
    getPropertyTypeBadge(trans, propertyTypeId),

    // No auction badge
    noAuction > 0 && trans('hide_auctions', { capitalize: true }),

    // Terrace badge
    terrace > 0 && trans('lbl_terrace'),

    // Balcony badge
    balcony > 0 && trans('lbl_terrace'),

    // Rooms badge
    getRoomsBadge(
      trans,
      minLocals > 0 ? String(minLocals) : undefined,
      maxLocals > 0 ? String(maxLocals) : undefined
    ),

    // Auction badge
    getAuctionBadge(trans, contract, inAuction),

    // Elevator badge
    elevator > 0 && trans('lbl_elevator'),

    // Smoker badge
    smoker > 0 && trans('smokers_allowed'),

    // Animals badge
    animals > 0 && trans('animals_allowed'),

    // EnergyRange badge
    energy && energy.class && getEnergyRangeBadge(trans, energy.class),
  ].filter(nonFalsy);
};
