import { useTranslations } from '@pepita-react/i18n';

import { PepitaAlert } from 'src/libs/ui/pepita-alert';

export function VisitRequestSentBox() {
  const { trans } = useTranslations();

  return (
    <PepitaAlert variant="success">
      <span
        dangerouslySetInnerHTML={{
          __html: trans('lbl_new_visit_request_sent', {
            params: ['<b>', '</b>'],
          }),
        }}
      ></span>
    </PepitaAlert>
  );
}
