import { useTranslations } from '@pepita-react/i18n';

import { PepitaAlert } from 'src/libs/ui/pepita-alert';

import css from './styles.module.scss';

export function AlertBox() {
  const { trans } = useTranslations();

  return (
    <PepitaAlert variant="info" customClass={css['in-visitRequestAlertBox']}>
      <p
        dangerouslySetInnerHTML={{
          __html: trans('visit_request_modal_text', {
            params: ['<b>', '</b>'],
          }),
        }}
      />
    </PepitaAlert>
  );
}
