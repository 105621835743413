import { useEffect, useRef, useState } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useRouter } from 'next/router';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { ENTITY_TYPE } from 'src/components/GeographySearch/utils';
import { geographySearchAtom } from 'src/components/ReactGeographySearch/atoms';

import {
  CATEGORIES,
  CONTRACT,
  NEW_COMMERCIAL_MAPPING,
} from 'src/constants/typologies';

import { useListingSearchParams } from './useListingSearchParams';

import type { SearchListingData } from 'src/types/real-estate';
import type { SearchParams } from 'src/types/search';
import type { Language } from 'src/types/translations';

import { http } from 'src/utils/client/http';
import { formatDate } from 'src/utils/date';
import {
  deepEqual,
  forceNumberValuesToString,
  removeEmptyKeys,
} from 'src/utils/object';
import { deserializeFromUrl, serializeIntoUrl } from 'src/utils/querystring';
import { decodeMapCenterQueryParams } from 'src/utils/searchListMapCenterQueryParams';
import { alternateAbsoluteURL } from 'src/utils/url';

type StoredSearchType = SearchParams & {
  fkCategoria: string;
  fkContratto: string;
  fkProvincia: string;
  fkComune: string;
  token: string;
  verticaleSito: string;
  dataUltimaVisita: string;
  datetime: number;
};

export const STORAGE_KEY = 'imm_ultimeRicerche';
export const LATEST_STORAGE_KEY = 'imm_ultimaRicerca';
const MAX_SEARCHES = 50;

const formatPolygon = (searchParams: SearchParams) => {
  let polygon;

  if (searchParams['vrt']) {
    polygon = {
      mode: 'polygon',
      area: searchParams['vrt']
        .split(';')
        .map((val) => val.split(',').map(parseFloat)),
    };
  } else if (searchParams['raggio'] && searchParams['centro']) {
    polygon = {
      mode: 'circle',
      area: {
        radius: searchParams['raggio'],
        center: searchParams['centro']
          .split(';')
          .map((val) => val.split(','))[0],
      },
    };
  }

  return polygon;
};

const cleanSearchUrl = (url: string) => {
  const urlParams = decodeMapCenterQueryParams(deserializeFromUrl(url));
  const index = url.indexOf('?');
  const urlWithoutParams = index !== -1 ? url.substring(0, index) : url;

  return serializeIntoUrl(urlWithoutParams, {
    ...urlParams,
    imm_source: undefined,
    id: undefined,
    editSearchAction: undefined,
    lat: undefined,
    lng: undefined,
    zoom: undefined,
  });
};

const buildLastSearch = (
  searchParams: SearchParams,
  token: string,
  verticaleSito: string,
  url: string
) => {
  const now = new Date();
  const datetime = Math.floor(now.getTime() / 1000);

  const lastSearch: StoredSearchType = {
    fkMacrozonaV2: searchParams.idMZona
      ? searchParams.idMZona.join(',')
      : undefined,
    poligono:
      searchParams.vrt || searchParams.raggio
        ? formatPolygon(searchParams)
        : undefined,
    fkCategoria: searchParams.idCategoria
      ? String(searchParams.idCategoria)
      : undefined,
    fkContratto: searchParams.idContratto
      ? String(searchParams.idContratto)
      : undefined,
    fkProvincia: searchParams.idProvincia
      ? String(searchParams.idProvincia)
      : undefined,
    fkComune: searchParams.idComune ? String(searchParams.idComune) : undefined,
    fkRegione: searchParams.fkRegione
      ? String(searchParams.fkRegione)
      : undefined,
    token,
    verticaleSito,
    dataUltimaVisita: formatDate(now),
    datetime,
    ...forceNumberValuesToString(searchParams),
    vrt: undefined,
    raggio: undefined,
    centro: undefined,
    idMZona: undefined,
    idCategoria: undefined,
    idContratto: undefined,
    idProvincia: undefined,
    idComune: undefined,
    boxAuto: undefined,
    maxLat: undefined,
    maxLng: undefined,
    minLat: undefined,
    minLng: undefined,
    arredato: searchParams.arredato === 'on' ? '1' : undefined,
    fkTipoProprieta: searchParams.tipoProprieta,
    fkStato: searchParams.stato,
    url: cleanSearchUrl(url),
    idIndirizzoPoligoni: searchParams.idIndirizzoPoligoni,
  };

  const extraFields: any = {};

  // Populating extrafields
  if (searchParams.boxAuto) {
    extraFields.boxAuto = searchParams.boxAuto;
    lastSearch.boxAuto = undefined;
  }

  if (searchParams.giardino) {
    extraFields.giardino = searchParams.giardino;
    lastSearch.giardino = undefined;
  }

  if (searchParams.cantina) {
    extraFields.cantina = searchParams.cantina;
    lastSearch.cantina = undefined;
  }

  if (searchParams.piscina) {
    extraFields.piscina = searchParams.piscina;
    lastSearch.piscina = undefined;
  }

  if (searchParams.noAste) {
    extraFields.noAste = searchParams.noAste === '1';
    lastSearch.noAste = undefined;
  }

  if (searchParams.virtualTour) {
    extraFields.withVirtualTour = searchParams.virtualTour;
    lastSearch.virtualTour = undefined;
  }

  if (searchParams.balconeOterrazzo) {
    if (searchParams.balconeOterrazzo.includes('balcone')) {
      extraFields.balcone = '1';
    }

    if (searchParams.balconeOterrazzo.includes('terrazzo')) {
      extraFields.terrazzo = '1';
    }

    lastSearch.balconeOterrazzo = undefined;
  }

  if (searchParams.fasciaPiano) {
    extraFields.fkFasciaPiano = searchParams.fasciaPiano;
    lastSearch.fasciaPiano = undefined;
  }

  if (searchParams.idCategoria === CATEGORIES.NEGOZI_LOCALI_COMMERCIALI) {
    const tipologiaCommerciale = (searchParams.idTipologia || []).flatMap(
      (id) => NEW_COMMERCIAL_MAPPING[id] || []
    );

    if (tipologiaCommerciale.length) {
      extraFields.tipologiaCommerciale = tipologiaCommerciale;
    }

    lastSearch.idTipologia = undefined;
  }

  if (Object.keys(extraFields).length > 0) {
    lastSearch['extraFields'] = extraFields;
  }

  /**
   * Issue: https://indomio.atlassian.net/browse/DEV-7000
   * Storicamente le ricerche per contratto `aste` vengono identificate tramite:
   * - contratto = 1
   * - categoria = 14
   */
  if (searchParams.idContratto === CONTRACT.ASTE) {
    lastSearch.fkContratto = CONTRACT.VENDITA;
    lastSearch.fkCategoria = CATEGORIES.ASTE;
  }

  return removeEmptyKeys(lastSearch) as StoredSearchType;
};

const saveRemoteSearches = (latestSearches: StoredSearchType[]) =>
  http
    .post('/services/account/insertUltimeRicerche.php', {
      form: { latestSearches: JSON.stringify(latestSearches) },
    })
    .json();

const getLatestSearches = (lastSearch: StoredSearchType) => {
  const storageData = localStorage.getItem(STORAGE_KEY);
  let latestSearches: StoredSearchType[] = storageData
    ? JSON.parse(storageData)
    : [];
  const sameSearchIndex = latestSearches.findIndex(
    (search) => search.token === lastSearch.token
  );

  if (sameSearchIndex !== -1) {
    latestSearches[sameSearchIndex] = lastSearch;
  } else {
    latestSearches.sort((a, b) => a.datetime - b.datetime);
    latestSearches = latestSearches.slice(0, MAX_SEARCHES - 1);
    latestSearches.push(lastSearch);
  }

  return latestSearches;
};

type LastSearchWithoutDate = Optional<
  Omit<StoredSearchType, 'datetime' | 'dataUltimaVisita'>
>;

export const useSaveLastSearches = (
  searchData: SearchListingData | undefined
) => {
  const token = searchData?.suggestedSearchData?.token;
  const searchParams = useListingSearchParams();
  const geographyData = useAtomicStateAtomValue(geographySearchAtom);
  const { user } = useAuthContext();
  const { asPath, locale, defaultLocale } = useRouter();
  const prevLastSearchWithoutDate = useRef<LastSearchWithoutDate>(undefined);

  const [remoteSaveCompleted, setRemoteSaveCompleted] =
    useState<boolean>(false);

  useEffect(() => {
    if (!token) return;

    // Disable last searches for countries and regions
    if (
      geographyData === null ||
      geographyData?.value[0]?.type === ENTITY_TYPE.country ||
      geographyData?.value[0]?.type === ENTITY_TYPE.region ||
      searchParams.noAgenzie === '1' ||
      searchParams.lowcost === '1' ||
      searchParams.seaView === '1' ||
      searchParams.seaDistance
    ) {
      setRemoteSaveCompleted(true);

      return;
    }

    const lastSearch = buildLastSearch(
      JSON.parse(JSON.stringify(searchParams)),
      token,
      searchData?.suggestedSearchData?.verticaleSito,
      alternateAbsoluteURL(
        asPath,
        defaultLocale as Language,
        locale as Language
      )
    );

    const { datetime, dataUltimaVisita, ...lastSearchWithoutDate } = lastSearch;

    // Disable last searches if subsequent searches are equal.
    if (deepEqual(lastSearchWithoutDate, prevLastSearchWithoutDate.current)) {
      setRemoteSaveCompleted(true);

      return;
    }

    prevLastSearchWithoutDate.current = lastSearchWithoutDate;

    setRemoteSaveCompleted(false);

    localStorage.setItem(LATEST_STORAGE_KEY, JSON.stringify(lastSearch));

    const latestSearches = getLatestSearches(lastSearch);

    if (user) {
      saveRemoteSearches(latestSearches).then((response) => {
        if (response?.status === 1) {
          localStorage.removeItem(STORAGE_KEY);
          setRemoteSaveCompleted(true);
        }
      });
    } else {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(latestSearches));
    }
  }, [
    token,
    geographyData,
    searchParams,
    searchData?.suggestedSearchData?.verticaleSito,
    asPath,
    defaultLocale,
    locale,
    user,
  ]);

  return remoteSaveCompleted;
};
