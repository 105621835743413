import type { FormEvent, SyntheticEvent } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { UserData } from '@indomita-react/auth-provider';
import { useAuthContext } from '@indomita-react/auth-provider';
import { PrivacyCheckbox } from '@indomita-react/privacy-checkbox';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';
import { useAtom, useAtomValue } from 'jotai';

import { isPriceProposalSentAtom } from 'src/atoms/isPriceProposalSentAtom';

import { authModalAtom } from 'src/components/AuthModal/atoms/authModalAtom';
import { Manager } from 'src/components/Manager';
import { Referent } from 'src/components/Referent';
import { VisitRequest } from 'src/components/VisitRequest';
import { useVisitRequest } from 'src/components/VisitRequest/hooks/useVisitRequest';
import { VisitRequestSentBox } from 'src/components/VisitRequest/SentBox';

import { SUPERVISOR_TYPE } from 'src/constants/real-estate';

import { useDelayedLoader } from 'src/hooks/useDelayedLoader';
import { useFocusRef } from 'src/hooks/useFocusRef';
import { useContactForm } from './hooks/useContactForm';

import type { ContactFormData, SourceValue } from 'src/libs/contact';
import { getContactListingType } from 'src/libs/contact';
import { PepitaAlert } from 'src/libs/ui/pepita-alert';
import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaSnackbar, usePepitaSnackbar } from 'src/libs/ui/pepita-snackbar';
import { PepitaTextField } from 'src/libs/ui/pepita-text-field';

import type {
  AgencyLite,
  PhoneTypes,
  Socials,
  TrovaKasa,
} from 'src/types/real-estate';
import type { RealEstate } from 'src/types/real-estate';
import type { ListRealEstate } from 'src/types/real-estate-list';
import type { RealEstateLite } from 'src/types/real-estate-lite';

import { isEmail } from 'src/utils/form-validators';
import { getCssModuleClassNames, goToThreadPage } from './utils';

import type { PriceProposalDialogState } from 'src/views/RealEstate';
import { PriceProposalSentAlert } from 'src/views/RealEstate/components/PriceProposalSentAlert';

import css from './index.module.scss';

const cssModuleClassNames = getCssModuleClassNames(css);

export type ContactFormProps = {
  data?: RealEstate | ListRealEstate | RealEstateLite;
  agency?: AgencyLite;
  socials?: Socials;
  inPage?: boolean;
  isPreviewMode?: boolean;
  /**
   * The variable that must be passed to the send-lead api
   */
  source?: SourceValue;
  textareaPlaceholder: string;
  textareaPrefilledValue?: string;
  customClass?: string;
  hideFields?: boolean;
  hasVisitRequest?: boolean;
  trovakasa?: TrovaKasa;
  isPriceProposalVisible?: boolean;
  onSubmitContact?: (contactData: Partial<ContactFormData>) => void;
  onVisitSubmit?: ({ response, data, visitRequestData }) => void;
  onShowPhoneClick?: (phoneType: PhoneTypes) => void;
  onContactClose?: () => void;
  openPriceProposalDialog?: (
    openedFrom: PriceProposalDialogState['openedFrom']
  ) => void;
};

export function ContactForm({
  data,
  agency,
  socials,
  source,
  textareaPlaceholder,
  textareaPrefilledValue,
  inPage,
  customClass,
  isPreviewMode,
  hideFields,
  hasVisitRequest,
  trovakasa,
  isPriceProposalVisible,
  onSubmitContact,
  onVisitSubmit,
  onShowPhoneClick,
  onContactClose,
  openPriceProposalDialog,
}: ContactFormProps) {
  const textAreaRef = useFocusRef<HTMLTextAreaElement>(null, {
    enabled: !inPage,
  });
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { trans } = useTranslations();
  const { user } = useAuthContext();

  const advertiser = data?.advertiser;
  const contract = data?.contract;
  const properties = data?.properties;

  const contactForm = useContactForm(
    {
      agencyId: agency?.id,
      listingId: data?.id,
      listingType: getContactListingType(data?.type),
      isUnicredit: agency?.customType === 'unicredit',
      source,
      originalListing:
        data ?? ({} as RealEstate | ListRealEstate | RealEstateLite),
      message: textareaPrefilledValue,
    },
    advertiser,
    contract,
    properties,
    socials,
    onSubmitContact,
    agency,
    onContactClose,
    trovakasa,
    data?.dataType
  );

  const { isVisitJustSent, visitRequestSentBox, hideVisitRequestSentBox } =
    useVisitRequest(
      undefined,
      data
        ? {
            id: data.id,
            type: data.type,
          }
        : undefined
    );

  const [isPriceProposalSent, setIsPriceProposalSent] = useAtom(
    isPriceProposalSentAtom
  );

  const showChatBox =
    contactForm.socialsEnabled ||
    contactForm.leadSentBox ||
    visitRequestSentBox ||
    isPriceProposalSent;

  const showPrivacy = !user && !contactForm.leadSentBox && !visitRequestSentBox;

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (contactForm.socialsEnabled && socials) {
      goToThreadPage(socials.url);
    } else if (contactForm.leadSentBox || visitRequestSentBox) {
      // removes chat boxes (lead or visit)
      contactForm.hideLeadSentBox();
      hideVisitRequestSentBox();
    } else {
      contactForm.handleSubmit();
    }
  }

  const authModal = useAtomValue(authModalAtom);

  const setSnackbar = usePepitaSnackbar();

  const getCtaText = (): string => {
    if (contactForm.socialsEnabled) {
      return socials?.cta || '';
    }

    if (contactForm.leadSent) {
      return trans('act_socials_send_another_contact');
    }

    return trans('act_send_message');
  };

  const getVisitRequestCtaText = (): string => {
    if (socials && socials.withVisit) {
      return socials.ctaVisit || '';
    }

    if (isVisitJustSent) {
      return trans('act_request_new_visit');
    }

    return trans('act_request_visit');
  };

  useEffect(() => {
    if (trovakasa && authModal.open && contactForm.status.type !== 'error') {
      return;
    }

    if ('message' in contactForm.status) {
      setSnackbar(
        <PepitaSnackbar
          variant={contactForm.status.type === 'error' ? 'error' : 'success'}
        >
          {contactForm.status.message}
        </PepitaSnackbar>
      );
    }
  }, [authModal.open, contactForm.status, setSnackbar, trovakasa]);

  const isDisabled = contactForm.status.type === 'loading';
  const isLoading = useDelayedLoader(isDisabled);

  const isPrivate =
    advertiser?.supervisor?.type === SUPERVISOR_TYPE.PRIVATE_USER;

  const isUserEmailValid = user?.email
    ? isEmail<UserData>('')(user, 'email').valid
    : false;

  const [showFieldsContact, setShowFieldsContact] = useState(false);
  const stopSubmit =
    (hideFields &&
      !showFieldsContact &&
      !user?.phone &&
      !contactForm.socialsEnabled) ||
    isPriceProposalSent;

  useEffect(() => {
    const textArea = textAreaRef.current;

    if (textArea && !inPage) {
      const text = textArea.innerHTML.length;

      textArea.setSelectionRange(text, text);
    }
  }, [textAreaRef, inPage]);

  const toggleShowFields = useCallback(
    (e: FocusEvent) => {
      e.preventDefault();
      setIsPriceProposalSent(false);
      setShowFieldsContact(true);
      hideVisitRequestSentBox();
      textAreaRef.current?.removeEventListener('focus', toggleShowFields);
      buttonRef.current?.removeEventListener('click', toggleShowFields);
    },
    [hideVisitRequestSentBox, setIsPriceProposalSent, textAreaRef]
  );

  useEffect(() => {
    if (textAreaRef.current) {
      if (hideFields) {
        const ref = textAreaRef.current;

        ref.addEventListener('focus', toggleShowFields);

        return () => {
          ref.removeEventListener('focus', toggleShowFields);
        };
      }
    }
  }, [hideFields, textAreaRef, toggleShowFields]);

  useEffect(() => {
    if (!buttonRef.current) return;

    if (stopSubmit) {
      const ref = buttonRef.current;

      ref.addEventListener('click', toggleShowFields);

      return () => {
        ref.removeEventListener('click', toggleShowFields);
      };
    }
  }, [stopSubmit, toggleShowFields]);

  const getChatAlertBoxChild = (): React.ReactNode => {
    if (showChatBox) {
      if (visitRequestSentBox) {
        return <VisitRequestSentBox />;
      }

      if (contactForm.leadSentBox) {
        return <LeadSentBox isPrivate={isPrivate} />;
      }

      if (isPriceProposalSent) return <PriceProposalSentAlert />;

      return contactForm.socialsEnabled && socials ? (
        <LeadHistoryBox socials={socials} />
      ) : null;
    }

    return (
      <div
        className={clsx('nd-form', cssModuleClassNames('in-lead__chatForm'))}
      >
        <PepitaTextField
          customClass={cssModuleClassNames('in-lead__textarea')}
          textarea
          name="message"
          rows={inPage ? 2 : 4}
          placeholder={textareaPlaceholder}
          onChange={contactForm.handleChange('message')}
          value={contactForm.data.message}
          ref={textAreaRef}
        />
        {(!hideFields || showFieldsContact) && (
          <>
            <PepitaTextField
              name="name"
              floatingLabel
              label={trans('lbl_name', { capitalize: true })}
              placeholder={trans('contact_form_name_placeholder', {
                capitalize: true,
              })}
              status={contactForm.getErrorMessage('name') ? 'error' : undefined}
              statusMessage={contactForm.getErrorMessage('name')}
              onChange={contactForm.handleChange('name')}
              value={contactForm.data.name}
            />
            <PepitaTextField
              name="email"
              type="email"
              disabled={isUserEmailValid}
              floatingLabel
              label={trans('lbl_email')}
              placeholder={trans('contact_form_email_placeholder', {
                capitalize: true,
              })}
              status={
                contactForm.getErrorMessage('email') ? 'error' : undefined
              }
              statusMessage={contactForm.getErrorMessage('email')}
              onBlur={contactForm.handleValidateTextInput('email')}
              onChange={contactForm.handleChange('email')}
              value={contactForm.data.email}
            />
            <PepitaTextField
              name="phone"
              type="tel"
              floatingLabel
              label={trans('lbl_phone')}
              placeholder={trans('contact_form_phone_placeholder', {
                capitalize: true,
              })}
              statusMessage={contactForm.getErrorMessage('phone')}
              status={contactForm.isNotValid('phone') ? 'error' : undefined}
              onBlur={contactForm.handleValidateTextInput('phone')}
              onChange={contactForm.handleChange('phone')}
              value={contactForm.data.phone}
            />
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <section
        className={clsx(cssModuleClassNames('in-lead__container'), customClass)}
      >
        {!isPreviewMode && (
          <form
            className={clsx(
              'nd-form',
              cssModuleClassNames(
                'in-lead__form',
                showChatBox ? 'in-lead__chat--active' : null
              )
            )}
            data-cy1="contact-form"
            data-cy2={contactForm.status.type}
            onSubmit={handleSubmit}
            autoComplete="off"
            noValidate
          >
            {inPage && (
              <p className={css['in-lead__title']}>
                {`${trans('act_contact_advertiser', {
                  capitalize: true,
                })} `}
              </p>
            )}

            {/* visit|lead|socials chat alert box */}
            {getChatAlertBoxChild()}
            {(!hideFields || showFieldsContact) && showPrivacy && (
              <PrivacyCheckbox
                error={contactForm.getErrorMessage('privacy')}
                onChange={contactForm.handleChange('privacy')}
                value={contactForm.data.privacy}
              />
            )}

            {isPriceProposalVisible ? (
              <>
                <PepitaButton
                  ref={buttonRef}
                  type="submit"
                  variant="accent"
                  disabled={isDisabled}
                  block
                  loading={isLoading}
                  customClass={css['in-lead__submit']}
                  icon={{ name: 'chat' }}
                >
                  {getCtaText()}
                </PepitaButton>
                {hasVisitRequest && (
                  <>
                    <span className={css['in-lead__or']}>
                      {trans('lbl_or', { capitalize: true })}
                    </span>
                    <VisitRequest
                      listing={data}
                      source={source}
                      buttonProps={{
                        block: true,
                      }}
                      customClass={clsx(
                        css['in-lead__visitButton'],
                        css['has-newLayout']
                      )}
                      text={getVisitRequestCtaText()}
                      onSubmit={onVisitSubmit}
                    />
                    <PepitaButton
                      type="button"
                      block
                      icon={{ name: 'euro-bubble' }}
                      customClass={css['in-lead__proposePrice']}
                      onClick={(e: SyntheticEvent) => {
                        e.preventDefault();
                        openPriceProposalDialog?.('stickySection');
                      }}
                    >
                      {trans('lbl_propose_price', { capitalize: true })}
                    </PepitaButton>
                  </>
                )}
              </>
            ) : (
              <>
                <PepitaButton
                  ref={buttonRef}
                  type="submit"
                  variant="accent"
                  disabled={isDisabled}
                  block
                  loading={isLoading}
                  icon={{ name: 'chat' }}
                >
                  {getCtaText()}
                </PepitaButton>
                {hasVisitRequest && (
                  <VisitRequest
                    listing={data}
                    source={source}
                    buttonProps={{
                      block: true,
                    }}
                    customClass={css['in-lead__visitButton']}
                    text={getVisitRequestCtaText()}
                    onSubmit={onVisitSubmit}
                  />
                )}
              </>
            )}
          </form>
        )}
        <div
          className={cssModuleClassNames(
            'in-lead__content--albatross',
            'in-lead__contentData'
          )}
          data-cy="agency-data"
        >
          {/* utente|agente|delegato */}
          {advertiser?.supervisor && (
            <Manager
              supervisor={advertiser.supervisor}
              onShowPhoneClick={onShowPhoneClick}
            />
          )}

          {/* agenzia */}
          {agency && (
            <Referent
              agency={agency}
              supervisor={advertiser?.supervisor}
              customType={agency.customType}
              link
              onShowPhoneClick={onShowPhoneClick}
            />
          )}
        </div>
      </section>
    </>
  );
}

export const leadSentBoxText = (
  isPrivate: boolean,
  trans: (key: string) => string
) => {
  return isPrivate
    ? trans('lbl_socials_new_message_sent_private')
    : trans('lbl_socials_new_message_sent_agency');
};

function LeadSentBox({ isPrivate }: { isPrivate: boolean }) {
  const { trans } = useTranslations();

  return (
    <PepitaAlert variant="success">
      {leadSentBoxText(isPrivate, trans)}
    </PepitaAlert>
  );
}

function LeadHistoryBox({ socials }: { socials: Socials }) {
  return <PepitaAlert variant="info">{socials.label}</PepitaAlert>;
}

export { ContactFormDialog } from './components/ContactFormDialog';
export { ContactFormInPage } from './components/ContactFormInPage';
