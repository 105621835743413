import { useEffect, useRef } from 'react';

import { deepEqual } from 'src/utils/object';

export const useOnDeepEqualAtomChange = <T,>(
  atomValue: T,
  onChange: () => void
) => {
  const prevAtomValue = useRef(atomValue);

  useEffect(() => {
    if (!deepEqual(prevAtomValue.current, atomValue)) {
      prevAtomValue.current = atomValue;
      onChange();
    }
  }, [onChange, atomValue]);
};
