import { clsx } from 'clsx';
import Link from 'next/link';

import { AgencyPhone } from 'src/components/AgencyPhone';
import { AgencyPlaceholder } from 'src/components/AgencyPlaceholder';

import type { ADVERTISER_CUSTOM_TYPE } from 'src/constants/real-estate';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';

import type { AgencyLite, PhoneTypes, Supervisor } from 'src/types/real-estate';

import { getBankCustom } from './utils/getBankCustom';
import { getImage } from './utils/getImage';

import css from './styles.module.scss';

const addTrackingQuery = (url: string, trackingValue?: string) =>
  trackingValue ? `${url}?${trackingValue}` : url;

interface ReferentProps {
  agency: AgencyLite;
  supervisor?: Supervisor;
  customType?: ADVERTISER_CUSTOM_TYPE | string;
  link?: boolean;
  onClick?: () => void;
  onShowPhoneClick?: (phoneTypes: PhoneTypes) => void;
  customClass?: string;
}

export function Referent({
  agency,
  supervisor,
  customType,
  link,
  onClick,
  onShowPhoneClick,
  customClass,
}: ReferentProps) {
  const {
    imageUrls,
    type,
    displayName,
    agencyUrl,
    externalLinks,
    text,
    phones,
    track,
  } = agency;

  const { srcSetCustom, image } = getImage({
    customType,
    supervisorType: supervisor?.type,
    imageUrls: imageUrls?.large ?? undefined,
  });

  const { bankCustom } = getBankCustom(customType);

  const contentTop = (
    <>
      <PepitaFigure customClass={css['in-referent__figure']}>
        {image ? (
          <PepitaFigure.Image
            src={image}
            srcSet={srcSetCustom || undefined}
            customClass={css['in-referent__image']}
            alt={displayName && displayName}
          />
        ) : (
          <PepitaFigure.Content>
            <AgencyPlaceholder
              agencyType={type}
              customClass={css['in-referent__placeholder']}
              bordered
            />
          </PepitaFigure.Content>
        )}
      </PepitaFigure>

      {displayName && !bankCustom && <p>{displayName}</p>}
    </>
  );

  return (
    <div
      className={clsx(
        css['in-referent'],
        bankCustom && css['in-referent--custom'],
        (!supervisor || agency.showAgentPhone) &&
          Boolean(phones.length) &&
          css['in-referent__withPhone'],
        customClass
      )}
    >
      {agencyUrl && link ? (
        <Link
          href={agencyUrl}
          passHref
          target="_blank"
          role="link"
          tabIndex={0}
          onClick={onClick && onClick}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              onClick && onClick();
            }
          }}
        >
          {contentTop}
        </Link>
      ) : (
        contentTop
      )}

      {text && <p>{text}</p>}

      {(!supervisor || agency.showAgentPhone) &&
        !agency.showOnlyAgentPhone &&
        phones.length > 0 &&
        phones.map((phone) => (
          <AgencyPhone
            key={phone.value}
            type={phone.type}
            phoneNumber={phone.value}
            onShowPhone={() => onShowPhoneClick?.(phone.type)}
          />
        ))}

      {externalLinks && (
        <Link
          href={addTrackingQuery(externalLinks.url, track)}
          passHref
          className={css['in-referent__externalLink']}
          target="_blank"
          role="link"
          tabIndex={0}
          onClick={onClick && onClick}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              onClick && onClick();
            }
          }}
        >
          {externalLinks.title}
        </Link>
      )}
    </div>
  );
}
