import type { ADVERTISER_CUSTOM_TYPE } from 'src/constants/real-estate';

import type { Supervisor } from 'src/types/real-estate';

import ubiBanca from '../image/reference-custom/ubi/logo-ubi.png';
import ubiBanca2x from '../image/reference-custom/ubi/logo-ubi@2x.png';
import uniCreditLogoPartner from '../image/reference-custom/unicredit/logo.png';
import uniCreditLogo from '../image/reference-custom/unicredit/logo.svg';

import { getBankCustom } from './getBankCustom';

interface GetImageArgs {
  customType?: ADVERTISER_CUSTOM_TYPE | string;
  supervisorType?: Supervisor['type'];
  imageUrls?: string;
}

export const getImage = ({
  customType,
  supervisorType,
  imageUrls,
}: GetImageArgs) => {
  const { bankCustom } = getBankCustom(customType);

  let customImages: Nullable<string>;

  switch (bankCustom) {
    case 'unicredit':
      customImages =
        supervisorType === 'agent' ? uniCreditLogoPartner.src : uniCreditLogo;
      break;
    case 'ubi':
      customImages = ubiBanca.src;
      break;
    default:
      customImages = null;
      break;
  }

  const srcSetCustom =
    bankCustom && bankCustom === 'ubi' ? `${ubiBanca2x.src} 2x` : null;

  const image = customImages || imageUrls;

  return { srcSetCustom, image };
};
