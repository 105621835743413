import type { ENTITY_TYPE } from '@indomita-website/map-polygons';
import type { TrovakasaResponse } from 'src/repository/trovakasa/api';

import { isFeatureEnabled } from 'src/config/features-toggle';

import type { SendLeadResponse } from 'src/types/send-lead';
import type { ContactData, SaveSuggestedProps } from './types';

import { http } from 'src/utils/client/http';

import { LATEST_STORAGE_KEY } from 'src/views/ListingSearch/hooks/useSaveLastSearches';

const SAVE_SUGGESTED_URL = '/api/utente/ricerche/suggerite';

const getSuggested = () => localStorage.getItem(LATEST_STORAGE_KEY);

export const sendLead = (data: ContactData): Promise<SendLeadResponse> => {
  return http
    .post('/api-next/send-lead/', {
      json: {
        listingId: data.listingId,
        agencyId: data.agencyId,
        listingType: data.listingType,
        message: data.message || '',
        email: data.email || '',
        name: data.name || '',
        phone: data.phone || '',
        source: data.source,
        siteFrom: data.mobile ? 'MO2' : undefined,
        sale: data.sale ? 1 : 0,
        isUnicredit: data.isUnicredit ? 1 : 0,
        /**
         * Questo parametro è utilizzato per inviare i contatti
         * a beezbezz (ricerca estero)
         *
         * Questi annunci non li gestiamo al momento
         */
        partner: false,
        privacy: 1,
        __lang: data.lang || '',
        estateId: data.estateId,
        /**
         * Parametri in caso di richiesta di visita
         */
        visitRequest: data.isVisitRequest,
        visitDays: data.visitDays,
        visitTimetables: data.visitTimetables,
        visitType: data.visitType,
        asSoonAsPossible: data.asSoonAsPossible,
        atAnyTime: data.atAnyTime,
      },
    })
    .json<SendLeadResponse>();
};

export const saveSuggested = ({
  listingId,
  listingType,
  email,
}: SaveSuggestedProps) =>
  http.post(SAVE_SUGGESTED_URL, {
    form: {
      idAnnuncio: listingId,
      type: listingType === 'project' ? 'project' : 'ad',
      search: getSuggested(),
      username: email,
    },
  });

export const getShowTrovakasa = async (
  {
    source,
    realEstateId,
    geographyType,
    geographyId,
  }: {
    source: string;
    realEstateId?: number;
    geographyType?: ENTITY_TYPE;
    geographyId?: string;
  },
  defaultValue = false
) => {
  if (!isFeatureEnabled('TROVAKASA_API')) {
    return defaultValue;
  }

  const { show } = await http
    .get('/api-next/trovakasa/', {
      searchParams: {
        id: realEstateId,
        source,
        geographyType,
        geographyId,
      },
    })
    .json<TrovakasaResponse>();

  return show;
};
