import { useCallback } from 'react';

import { useSendGA4Event } from 'src/tracking/ga/hooks/useSendGAEvent';
import { getGA4SectionName } from 'src/tracking/ga/utils/store';
import { getRealEstateSectionFromEntryPoint } from 'src/tracking/utils';
import { GATrackingEvent, SectionName } from 'src/tracking/utils/enums';
import { getContactFormTrackingData } from '../tracking/utils';
interface UseContactFormTrackingPops {
  realEstateId: number;
}

export const useContactFormTracking = ({
  realEstateId,
}: UseContactFormTrackingPops) => {
  const sendGA4Event = useSendGA4Event();
  const trackingData = getContactFormTrackingData(
    getRealEstateSectionFromEntryPoint(),
    realEstateId
  );
  const sectionName = getGA4SectionName();

  const trackSaveSearchImpression = useCallback(() => {
    if (trackingData) {
      sendGA4Event(GATrackingEvent.SAVE_SEARCH_IMPRESSION, {
        view_type: 'contact',
        section_name: sectionName,
      });
    }
  }, [sectionName, sendGA4Event, trackingData]);

  const trackListingSaveSearch = useCallback(() => {
    if (trackingData) {
      sendGA4Event(GATrackingEvent.SAVE_SEARCH_CONFIRMED, {
        view_type: sectionName === SectionName.LISTING_DETAIL ? 'contact' : '',
      });
    }
  }, [sectionName, sendGA4Event, trackingData]);

  return { trackSaveSearchImpression, trackListingSaveSearch };
};
