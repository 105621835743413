import { useTranslations } from '@pepita-react/i18n';

import { PepitaAlert } from 'src/libs/ui/pepita-alert';

export function PriceProposalSentAlert() {
  const { trans } = useTranslations();

  return (
    <PepitaAlert variant="success">
      <span
        dangerouslySetInnerHTML={{
          __html: trans('lbl_proposal_sent'),
        }}
      />
    </PepitaAlert>
  );
}
