import { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';
import { bannersDataAtom } from 'src/atoms/bannersDataAtom';

import { geographySearchParamsAtom } from 'src/components/GeographySearch/atoms';

import { http } from 'src/utils/client/http';
import { removeEmptyKeys } from 'src/utils/object';

import { agencyListQueryParamsAtom } from 'src/views/AgencyList/atoms/agencyListSearchParamsAtom';
import { useRealEstateSearchParams } from 'src/views/ListingSearch/hooks/useRealEstateSearchParams';

// TODO Con la 0.3 di state-atom questo lo posso fare nella funzione di setup dell'atom
// https://gitlab.pepita.io/pepita/pepita-frontend/state-atom#effects
export function useBanners(isAgencyListSearch = false) {
  const setBannersData = useSetAtom(bannersDataAtom);

  // Don't fetch banners on change of map bounds and zoom
  const { minLat, maxLat, minLng, maxLng, zoom, ...realEstateSearchParams } =
    useRealEstateSearchParams();

  const geographySearchParams = useAtomicStateAtomValue(
    geographySearchParamsAtom
  );
  const agencyListQueryParams = useAtomValue(agencyListQueryParamsAtom);

  const params = useMemo(() => {
    if (isAgencyListSearch) {
      // Nel caso della lista agenzia la categoria da passare è statica e sempre la 7
      return {
        ...geographySearchParams,
        ...agencyListQueryParams,
        idCategoria: '7',
      };
    }

    return realEstateSearchParams;
  }, [
    isAgencyListSearch,
    geographySearchParams,
    agencyListQueryParams,
    realEstateSearchParams,
  ]);

  const { data } = useQuery({
    queryKey: ['real-estate-list-banners', removeEmptyKeys(params)],
    queryFn: () =>
      http
        .get('/api-next/real-estate/banners/', {
          searchParams: {
            service: 'banners',
            ...params,
          },
        })
        .json(),
  });

  useEffect(() => {
    // Nella prima ricerca i dati vengono precaricati lato server
    // quindi saltiamo il primo fetch assumendo di avere già i dati
    // Tutto questo lo facciamo per anticipare il più possibile il caricamento
    // delle pubblicità in modo da diminiuire i layout shift in pagina
    if (!data) {
      return;
    }

    setBannersData(data);
  }, [data, setBannersData]);
}
